<template>
    <v-form class="pa-5" v-if="modelValue">
      <v-card-text v-on="error">{{ error }}</v-card-text>
      <v-text-field
          prepend-icon="mdi-shopping-search"
          v-model="search.customer_id"
          label="Search Stripe by customer id or email (e.g. cus_MQdQy3eWoGbbBp)"
      >
        <template v-slot:append>
          <v-btn
              depressed
              tile
              color="primary"
              class="ma-0"
              @click="fetchSubDataInStripe()">
            Search & Fill out form below
          </v-btn>
        </template>
      </v-text-field>
      <v-text-field
          prepend-icon="mdi-account-search"
          v-model="search.username_or_email"
          label="Search Readtheory by username or email"
      >
        <template v-slot:append>
          <v-btn
              depressed
              tile
              color="primary"
              class="ma-0"
              @click="getDataFromDatabase()">
            Search & Fill out form below
          </v-btn>
        </template>
      </v-text-field>
      <v-divider></v-divider>
      <v-alert v-if="errorMessage" type="warning">{{ errorMessage }}</v-alert>
      <v-row>
        <v-col cols="3"><v-checkbox v-model="modelValue.active" :label="`Subscription Enabled`"></v-checkbox></v-col>
      </v-row>
      <v-text-field
          @click:prepend="previewContact"
          prepend-icon="mdi-account"
          v-model="modelValue.customer_id"
          label="Stripe Customer Id"
      ></v-text-field>
      <v-text-field
          prepend-icon="mdi-autorenew"
          v-model="modelValue.paid_amount"
          type="number"
          label="Paid Amount"
      ></v-text-field>
      <v-text-field
          prepend-icon="mdi-autorenew"
          v-model="modelValue.invoice_id"
          label="Invoice Id"
      ></v-text-field>
      <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="start_date_for_picker"
              label="Subscription Start Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="start_date_for_picker"
            @input="menu = false"
        ></v-date-picker>
      </v-menu>
      <v-menu
          v-model="endMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="endDateForPicker"
              label="Subscription End Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="endDateForPicker"
            :allowed-dates="allowedEndDates"
            @input="endMenu = false"
        ></v-date-picker>
      </v-menu>
      <v-text-field
          prepend-icon="mdi-account-group"
          v-model="modelValue.num_of_students"
          type="number"
          label="Number of students"
      ></v-text-field>

      <v-text-field
          prepend-icon="mdi-school"
          v-model="modelValue.num_of_classes"
          type="number"
          label="Number of classes"
      ></v-text-field>

      <v-text-field
          @click:prepend="previewSub"
          prepend-icon="mdi-autorenew"
          v-model="modelValue.sub_id"
          label="Stripe Subscription Id"
      ></v-text-field>
      <v-text-field
          @click:prepend="previewSub"
          prepend-icon="mdi-autorenew"
          v-model="modelValue.product_id"
          label="Stripe Product Id"
      ></v-text-field>

      <v-jsoneditor v-if="advanced" v-model="modelValue" :plus="true" height="500px"></v-jsoneditor>

    </v-form>
</template>
<script>
import _ from "lodash";
import VJsoneditor from 'v-jsoneditor'
import {mapMutations, mapState} from "vuex";
import {SUB_SERVICE_API, SUB_SERVICE_SECRET} from "@/constants";

const SUB_DEFAULTS = {
  active: true,
  customer_id: '',
  sub_id: '',
  start_date: '',
  price_id: '',
  product_id: '',
  status: '',
  paid_amount: 0,
  invoice_id: '',
  num_of_students: 40,
  num_of_classes: 9999,
}
export default {

  name: "SubscriptionEditor",
  components: {
    VJsoneditor
  },
  data() {
    return {
      errorMessage: null,
      user_id: null,
      email: '',
      date: null,
      menu: false,
      endMenu: false,
      advanced: false,
      search: {
        customer_id: '',
        username_or_email: ''
      },
      error: '',
    }
  },
  computed: {
    start_date_for_picker: {
      get() {
        let epochDate = _.result(this.modelValue, 'start_date', Date.now()/1000)
        if (typeof epochDate === 'string') {
          epochDate = new Date(epochDate).getTime()/1000;
        }
        return (new Date(epochDate * 1000).toISOString().substring(0, 10))
      },
      set(value) {
        this.modelValue = {
          ...this.modelValue,
          start_date: !_.isEmpty(value) ? new Date(value).getTime()/1000 : ''
        }
      }
    },
    endDateForPicker: {
      get() {
        let epochDate = _.result(this.modelValue, 'end_date', Date.now()/1000)
        if (typeof epochDate === 'string') {
          epochDate = new Date(epochDate).getTime()/1000;
        }
        if (!epochDate) {
          epochDate = Date.now()/1000
        }
        return (new Date(epochDate * 1000).toISOString().substring(0, 10))
      },
      set(value) {
        this.modelValue = {
          ...this.modelValue,
          end_date: !_.isEmpty(value) ? new Date(value).getTime()/1000 : ''
        }
      }
    },
    querySeachParams() {
      let url = ''
      if (!_.isEmpty(this.search.username_or_email) || (this.search.username_or_email && this.search.username_or_email.length > 2)) {
        url = 'query=' + encodeURIComponent(this.search.username_or_email);
        const search_fields = ['username', 'email']
        if (!_.isEmpty(search_fields)) url += '&query_fields=' + _.join(search_fields, ',')
      }
      return url;
      },
      ...mapState(['user','bulk_subscriptions']),
      modelValue: {
        get() {
          return this.bulk_subscriptions.subscriptionObj
        },
        set(value) {
          this.SET_SUB_OBJ(value)
        }
      },
      school_name: {
        get() {
          return this.bulk_subscriptions.school_name
        },
        set(value) {
          this.SET_SCHOOL_NAME(value)
        }
      },
  },
  methods: {
    ...mapMutations(['SET_SUB_OBJ', 'SET_SCHOOL_NAME']),
    allowedEndDates(value) {
      const endDate = !_.isEmpty(value) ? new Date(value).getTime()/1000 : '';
      return !this.modelValue.start_date || this.modelValue.start_date && endDate > this.modelValue.start_date;
    },
    previewSub() {
      window.open('https://dashboard.stripe.com/subscription/' + this.modelValue.product_id, '_blank');
    },
    previewContact() {
      window.open('https://dashboard.stripe.com/customers/' + this.modelValue.customer_id, '_blank');
    },
    init_ui(email) {
      this.modelValue = _.defaultsDeep(_.cloneDeep(this.modelValue), SUB_DEFAULTS);
      this.advanced = false;
    },
    show(email, user_id, subObj) {
      this.init_ui(email, subObj);
      this.email = email;
      this.user_id = user_id;
      this.dialog = true;
    },
    async fetchSubDataInStripe() {
      this.loading = true;
      const payload = {
        secret_key: SUB_SERVICE_SECRET,
        user_id: this.user_id
      }
      if (!_.isEmpty(this.search.customer_id)) {
        if (_.startsWith(this.search.customer_id,'cus_')) {
          payload.customer_id = this.search.customer_id
        } else {
          payload.email = this.search.customer_id
        }
      }
      const res = await fetch(SUB_SERVICE_API + '/subs/find', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(res => res.json())
      this.loading = false;
      if (res.success) {
        this.modelValue = _.defaultsDeep(res.data, SUB_DEFAULTS);
        this.modelValue.start_date = (new Date(this.modelValue.start_date * 1000).toISOString().substring(0, 10))

      } else {
        this.errorMessage = res.message;
      }
    },
    async getDataFromDatabase() {
      const sortBy = 'create_date'
      const sortDesc = true
      let url = `/user/list?`
      url += `limit=1&offset=0&sort=${sortBy}&desc=${sortDesc}&${this.querySeachParams}`;
      if (url !== this.lastFetchQuery) {
        this.isLoading = true
        try {
          if (this.cancelToken) {
            this.cancelToken.cancel("Operation canceled due to new request.")
          }
          this.cancelToken = this.axios.CancelToken
          const {data, status} = await this.axios(url, {cancelToken: this.cancelToken});
          if (status !== 200) {
            this.isLoading = false;
            this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          }
          const result = _.head(data.items.map(item => ({...item, roles: JSON.parse(item.roles), user_settings: JSON.parse(item.user_settings)})));
          const subObj = _.result(result, 'user_settings.subscription', null)
          if (subObj) {
            this.modelValue = _.defaultsDeep(_.cloneDeep(subObj), SUB_DEFAULTS);
            if (_.isEmpty(this.modelValue.start_date)) this.modelValue.start_date = (new Date(this.modelValue.start_date * 1000).toISOString().substring(0, 10));
          } else {
            this.errorMessage = 'Did not find user or subscription on this user';
          }
          const school_name = _.result(result, 'user_settings.school_name', null)
          if (school_name) {
            this.school_name = school_name
          }
        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.isLoading = false
        }
      }
    },

    onCancel() {
      this.dialog = false;
    },
    onOk() {
      if (this.validateForm()) {
        this.dialog = false;
        this.email = ''
        this.user_id = null;
        this.errorMessage = null
        const subObj = _.cloneDeep(this.modelValue)
        if (!_.isEmpty(subObj.start_date)) {
          subObj.start_date = new Date(subObj.start_date).getTime()/1000
        }
        this.$emit('subscription-updated', _.cloneDeep(subObj))
      }
    },
    validateForm() {
      return true;
    },
    showError(message) {
      this.error = message;
      setTimeout(() => {
        this.error = '';
      }, 3000);
    }
  }
}
</script>

<style scoped>

</style>
